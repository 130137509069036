<template>
    <div class="" id="page-profile-page">
      <b-card>
        <v-tabs background-color="white" color="accent-4" left>
          <v-tab>{{$t('APP_SETTINGS.NEWS')}}</v-tab>
          <v-tab>{{$t('APP_SETTINGS.SPONSORS')}}</v-tab>
          <v-tab>{{$t('APP_SETTINGS.CARDAPP_SETTINGS')}}</v-tab>
  
          <v-tab-item>
            <MemberProfileNews />
          </v-tab-item>

          <v-tab-item>
            <MemberProfileSponsors />
          </v-tab-item>


          <v-tab-item>
            <CardAppSettings />
          </v-tab-item>

  
        </v-tabs>
      </b-card>
  
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';

  import MemberProfileNews from '@/view/pages/ml/app_settings/MemberProfileNews.vue';
  import MemberProfileSponsors from '@/view/pages/ml/app_settings/MemberProfileSponsors.vue';
  import CardAppSettings from '@/view/pages/ml/app_settings/CardAppSettings.vue';


  import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
  
  export default {
    name: 'AppSettingsPage',
    components: {
      MemberProfileNews,
      MemberProfileSponsors,
      CardAppSettings,
    },
    mixins: [ toasts ],
    computed: {
      ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'sid', 'isTHS'])
    },
    async mounted() {
  
    },
    data() {
      return {
  
      };
    },
    watch: {
      currentCompanyId(newValue, oldvalue) {
        if (newValue) {
  
        }
      },
  
    },
    methods: {

    }
  };
  </script>
  