<template>
  <div id="page-card-app-settings">
    <memlist-checkbox 
      :text="$t('APP_SETTINGS.SHOW_NEWS_TAB')"
      style="margin-top: 20px;"
      v-model="local_item.show_news_tab"
    />

    <memlist-checkbox 
      :text="$t('APP_SETTINGS.SHOW_CARD_TAB')"
      style="margin-top: 20px;"
      v-model="local_item.show_card_tab"
    />

    <memlist-checkbox 
      :text="$t('APP_SETTINGS.SHOW_SPONSORS_TAB')"
      style="margin-top: 20px;"
      v-model="local_item.show_sponsors_tab"
    />

    <memlist-checkbox 
      :text="$t('APP_SETTINGS.SHOW_TICKETS_TAB')"
      style="margin-top: 20px;"
      v-model="local_item.show_tickets_tab"
    />


    <hr />

    <memlist-checkbox 
      :text="$t('APP_SETTINGS.LOGIN_WITH_BANKID')"
      style="margin-top: 20px;"
      v-model="local_item.login_with_bankid"
    />

    <memlist-checkbox 
      :text="$t('APP_SETTINGS.LOGIN_WITH_EMAIL')"
      style="margin-top: 20px;"
      v-model="local_item.login_with_email"
    />

    <hr />

    <div v-if="local_item.profile">

      <memlist-checkbox 
        :text="$t('MEMBER.PERSONNR')"
        style="margin-top: 20px;"
        v-model="local_item.profile.pn"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.FIRSTNAME')"
        style="margin-top: 20px;"
        v-model="local_item.profile.firstname"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.LASTNAME')"
        style="margin-top: 20px;"
        v-model="local_item.profile.lastname"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.EMAIL')"
        style="margin-top: 20px;"
        v-model="local_item.profile.email"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.PHONE')"
        style="margin-top: 20px;"
        v-model="local_item.profile.phone"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.DOB')"
        style="margin-top: 20px;"
        v-model="local_item.profile.dob"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.PUBLIC_ID')"
        style="margin-top: 20px;"
        v-model="local_item.profile.public_id"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.ADDRESS')"
        style="margin-top: 20px;"
        v-model="local_item.profile.address"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.CO')"
        style="margin-top: 20px;"
        v-model="local_item.profile.co"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.COUNTRY')"
        style="margin-top: 20px;"
        v-model="local_item.profile.country"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.ZIPCODE')"
        style="margin-top: 20px;"
        v-model="local_item.profile.zipcode"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.POST')"
        style="margin-top: 20px;"
        v-model="local_item.profile.post"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.REGION')"
        style="margin-top: 20px;"
        v-model="local_item.profile.region_code"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.REGION')"
        style="margin-top: 20px;"
        v-model="local_item.profile.commune_code"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.CNAME')"
        style="margin-top: 20px;"
        v-model="local_item.profile.cname"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.STUDENT_ID')"
        style="margin-top: 20px;"
        v-model="local_item.profile.student_id"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.PUBLIC_SEX')"
        style="margin-top: 20px;"
        v-model="local_item.profile.public_sex"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.AVD')"
        style="margin-top: 20px;"
        v-model="local_item.profile.avd"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.TITLE')"
        style="margin-top: 20px;"
        v-model="local_item.profile.title"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.ORG_NUMBER')"
        style="margin-top: 20px;"
        v-model="local_item.profile.org_number"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.INVOICE_ADDRESS')"
        style="margin-top: 20px;"
        v-model="local_item.profile.invoice_address"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.INVOICE_ZIPCODE')"
        style="margin-top: 20px;"
        v-model="local_item.profile.invoice_zipcode"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.INVOICE_POST')"
        style="margin-top: 20px;"
        v-model="local_item.profile.invoice_post"
      />

      <memlist-checkbox 
        :text="$t('MEMBER.INVOICE_COUNTRY')"
        style="margin-top: 20px;"
        v-model="local_item.profile.invoice_country"
      />
    
    </div>

    <div class="mr-32">
      <RightSaveButton
      ref="save-button"
      :text="$t('COMMON.SAVE')"
      @clicked="save()"
      style="margin-right: 128px;"
      class="ml-4 mt-4 mx-right"
    />
    </div>
    
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {
  name: 'CardAppSettings',
  components: {
    RightSaveButton
  },
  computed: {
    ...mapGetters(['isTHS', 'currentCompanyId','customer'])
  },
  mixins: [ toasts ],
  data() {
    return {
      local_item: {}
    };
  },
  async mounted() {
    await this.fetch_settings();
  },
  methods: {
    async save() {
      const res = await axios.put(`/customer/${this.customer.id}`, { app_settings: this.local_item });

      if (res.status === 204) {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('APP_SETTINGS.UPDATED'));
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('APP_SETTINGS.UNABLE_UPDATE'));
      }

      this.$refs['save-button'].stop();
    },
    async fetch_settings() {
      const res = await axios.get(`/customer/app/${this.customer.id}`);

      if (res.status === 200) {
        this.local_item = res.data.app_settings;
      }

      if (!this.local_item || this.local_item.show_news_tab === undefined) {
        this.local_item = {
          show_news_tab: false,
          show_card_tab: false,
          show_sponsors_tab: false,
          show_tickets_tab: false,
          
          login_with_bankid: false,
          login_with_email: false,
          profile: {}
        };
      }

      if (!this.local_item.profile) {
        this.local_item.profile = {};
      }
    },
  }
};
</script>
