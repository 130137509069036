<template>
  <div id="page-news">
    <!-- News Editor Modal -->
    <MemberProfileNewsEditorModal 
      ref="edit_news"
      :news_id="selected_news_id"
      @created="created"
      @updated="updated_news"
      @cancelled="cancelled"
    />

    <!-- News Table -->
    <MemberProfileNewsTable 
      ref="news_table"
      @on_select_item="on_select_item"
      @on_delete_item="on_delete_item"
      @create_clicked="create_clicked"
    />
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import MemberProfileNewsTable from '@/view/pages/ml/app_settings/MemberProfileNewsTable.vue';
import MemberProfileNewsEditorModal from '@/view/pages/ml/app_settings/MemberProfileNewsEditorModal.vue';

export default {
  name: 'MemberProfileNews',
  components: {
    MemberProfileNewsTable,
    MemberProfileNewsEditorModal
  },
  computed: {
    ...mapGetters(['isTHS', 'currentCompanyId', 'current_user'])
  },
  mixins: [ toasts ],
  data() {
    return {
      selected_news_id: null,
      items: []
    };
  },
  mounted() {

  },
  methods: {
    created(news) {
      this.toastr('success', this.$t('COMMON.OK'), this.$t('NEWS.CREATED'));
      this.$refs['news_table'].refresh();
    },
    updated_news(news, notify) {
      // Update the news table by refreshing it.
      this.$refs['news_table'].refresh();
      if (notify) {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('NEWS.UPDATED'));
      }
    },
    cancelled() {
      this.selected_news_id = null;
      this.$refs['news_table'].refresh();
    },
    create_clicked() {
      this.selected_news_id = null;
      this.$nextTick(() => {
        this.$refs['edit_news'].show();
      });
    },
    on_select_item(id) {
      this.selected_news_id = id;
      this.$nextTick(() => {
        this.$refs['edit_news'].show();
      });
    },
    async on_delete_item(id) {
      try {
        const res = await axios.delete(`/news/${id}`);
        if (res.status === 200) {
          this.$refs['news_table'].refresh();
          this.toastr('success', this.$t('COMMON.OK'), this.$t('NEWS.DELETED'));
        }
      } catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('NEWS.UNABLE_DELETE'));
      }
    }
  }
};
</script>
