<template>
  <div id="page-member-profile-sponsor-editor-modal">
    <memlist-modal
      size="xxl"
      ref="edit_form"
      :visible="show_modal"
      @close="on_close"
      hide_footer
    >
      <!-- Sponsor Name & Published Checkbox -->
      <div class="d-flex align-items-center mt-8">
        <memlist-text-input
          class="flex-grow-1"
          style="max-width: 320px;"
          id="name"
          name="name"
          :title="$t('SPONSOR.NAME')"
          layout="vertical"
          v-model="local_item.name"
          type="text"
        />
        <memlist-checkbox 
          class="ml-4"
          :text="$t('SPONSOR.IS_PUBLISHED')"
          style="margin-top: 54px;"
          v-model="local_item.is_published"
        />
      </div>

      <div class="logo-upload-section d-sm-flex align-items-center mb-4 mt-8">
        <!-- Logo Preview -->
        <div class="logo-preview">
          <img :src="local_item.logo_url" alt="Logo Preview" />
        </div>
        <!-- Upload Button -->
        <div class="upload-button ml-3">
          <a
            href="#"
            class="btn btn-primary font-weight-bolder font-size-sm my-2"
            @click.prevent="select_file"
          >
            <i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>
            {{$t('FILE.UPLOAD_FILE')}}
          </a>
          <input hidden type="file" id="file" ref="file" @change="handle_file_upload" />
        </div>
      </div>

      <!-- Sponsor Description -->
      <memlist-textarea
        class="mt-8"
        id="descr"
        name="descr"
        :title="$t('SPONSOR.DESCR')"
        layout="vertical"
        v-model="local_item.descr"
        rows="10"
      />

      <!-- Action Buttons -->
      <div class="modal-footer mt-4">
        <button class="btn btn-secondary" @click="on_close">
          {{ $t('COMMON.CANCEL') }}
        </button>
        <button class="btn btn-primary" @click="save_sponsor">
          {{ $t('COMMON.SAVE') }}
        </button>
      </div>
    </memlist-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { upload_file } from '@/core/services/fileUpload';

export default {
  name: 'MemberProfileSponsorEditorModal',
  props: ['sponsor_id'],
  mixins: [toasts],
  computed: {
    ...mapGetters(['currentCompanyId', 'customer'])
  },
  data() {
    return {
      local_item: {},
      show_modal: false,
    };
  },
  watch: {
    sponsor_id: {
      handler(new_val) {
        if (new_val) {
          this.fetch_sponsor(new_val);
        } else {
          this.local_item = {};
        }
      },
      immediate: true
    }
  },
  methods: {
    on_close() {
      this.show_modal = false;
    },
    show() {
      this.show_modal = true;
    },

    async handle_file_upload() {
      const file = this.$refs.file.files[0];

      if (file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      try {
        const newfile = await upload_file(file, { file_type: 'PUBLIC_IMAGE', is_public: true });

        this.local_item.logo_url = newfile.public_path;

        if (this.local_item.id) {
          this.save_sponsor();
        }

        this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.UPLOADED'));
      }
      catch (err) {
        console.error('handle_file_upload', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_UPLOAD'));
      }
      
    },

    select_file() {
      this.$refs.file.click();
    },


    async fetch_sponsor(id) {
      try {
        const res = await axios.get(`/sponsor/${id}`);
        if (res.status === 200) {
          this.local_item = res.data;
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SPONSOR.UNABLE_GET'));
        }
      } catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SPONSOR.UNABLE_GET'));
      }
    },
    async save_sponsor() {
      if (this.local_item.id) {
        const res = await axios.put(`/sponsor/${this.local_item.id}`, this.local_item);
        if (res.status === 200) {
          this.$emit('updated', this.local_item);
          this.on_close();
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SPONSOR.UNABLE_UPDATE'));
        }
      } else {
        this.local_item.customer_id = this.customer.id;
        this.local_item.company_id = this.currentCompanyId;

        const res = await axios.post(`/sponsor`, this.local_item);

        if (res.status === 201) {
          this.local_item = res.data;
          this.$emit('created', this.local_item);
          this.on_close();
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SPONSOR.UNABLE_CREATE'));
        }
      }
    }
  }
};
</script>

<style scoped>
</style>
